<template>
  <div>
    <b>#{{ item.id_curso }} -&nbsp;</b>
    <span>{{ item.nome_curso ? item.nome_curso : item.nome_curso_mae }}</span>
  </div>
</template>

<script>
export default {
  props: {
    item: { required: true },
    searchText: { required: true },
  },
};
</script>
<style>
.v-autocomplete-list {
  width: 100%;
  max-width: 100%;
  text-align: left;
  border: none;
  border-top: none;
  max-height: 400px;
  overflow-y: auto;
  border-bottom: 1px solid #86c4e8;
  position: absolute;
  z-index: 999;
}

.v-autocomplete-list-item {
  cursor: pointer;
  background-color: #fff;
  padding: 10px;
  border-bottom: 1px solid #86c4e8;
  border-left: 1px solid #86c4e8;
  border-right: 1px solid #86c4e8;
}
.v-autocomplete-list-item:last-child {
  border-bottom: none;
}
.v-autocomplete-list-item:hover {
  background-color: #eee;
}
</style>
